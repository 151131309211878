
function FooterBlock() {
    return (
        <div className="nk-footer nk-auth-footer-full">
            <div className="container wide-lg">
                <div className="row g-3">
                    <div className="col-lg-6 order-lg-last">
                        <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    Правила пользования
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">
                                    Пользовательское соглашение
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="nk-block-content text-left text-lg-left">
                            <p className="text-soft">
                                © 2023 StarsWay
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBlock;
