import React from 'react';
import SVGLogo from "./Logo";
import FooterBlock from "./FooterBlock";

const MainPage = () => {
    return (
        <div className="nk-app-root">
            <div className="nk-main">

                <div className="header-main header-main-s1 is-sticky is-transparent on-dark bg-azure-dim ">
                    <div className="container header-container">
                        <div className="header-wrap">
                            <div className="header-logo">
                                <SVGLogo/>
                            </div>
                            <div className="header-toggle">
                                <button className="menu-toggler" data-target="mainNav">
                                    <em className="menu-on icon ni ni-menu"/>
                                    <em className="menu-off icon ni ni-cross"/>
                                </button>
                            </div>
                            <nav className="header-menu">
                                <ul className="menu-list ms-lg-auto">
                                    <li className="menu-item active current-page">
                                        <a href="/signin" className="menu-link text-dark">
                                            Войти
                                        </a>
                                    </li>

                                </ul>
                                <ul className="menu-btns">
                                    <li>
                                        <a href="/signup" target="_blank" className="btn btn-primary btn-lg">
                                            Регистрация
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="header-content my-auto py-5 bg-blue-dim">
                    <div className="container py-5">
                        <div className="row flex-lg-row-reverse align-items-center justify-content-between g-gs">
                            <div className="col-lg-5 mb-n3 mb-lg-0">
                                <div className="header-image header-image-s2">
                                    <img
                                        src="https://media.discordapp.net/attachments/1092741511218729073/1139959200785629274/pb3.png"
                                        alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="header-caption">

                                    <h1 className="header-title ">
                                        Нативное размещение в TikTok и YouTube с проверенными блогерам
                                    </h1>
                                    <div className="header-text mt-4 mb-5">
                                        <h5 className="fw-medium ">
                                            Реклама на автомате: подберите блогера и запустите кампанию в несколько
                                            кликов!
                                        </h5>
                                    </div>
                                    <ul className="header-action btns-inline">
                                        <li>
                                            <a href="/signup" className="btn btn-danger btn-lg">
                                                <span>Купить размещение</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section section-service px-2 bg-white" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-head">
                                    <h2 className="title">Как работает платформа?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="row g-gs mt-3 mb-5 text-center">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">1</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Создайте аккаунт</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">2</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Создайте первую кампанию</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">3</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Загрузите креатив</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-gs mt-5 text-center">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">4</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Выберите блогеров</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">5</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Пополните баланс</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <div className="service service-s1">
                                        <div className="service-icon styled-icon styled-icon-s1">
                                            <p className="stepper-text">6</p>
                                        </div>
                                        <div className="service-text">
                                            <h4 className="title">Блогер публикует видео</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section section-tesimonial bg-blue-dim" id="reviews">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-head">
                                    <h2 className="title">
                                        Нам доверяют сотни компаний
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-gs gy-5">
                            <div className="col-lg-4 col-md-6">
                                <div className="review">
                                    <div className="review-content card card-shadow round-xl">
                                        <div className="card-inner card-inner-lg">
                                            <div className="review-rating rating rating-sm">
                                                <ul className="rating-stars">
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                </ul>
                                                <div className="rating-text">(4.6/5)</div>
                                            </div>
                                            <div className="review-text">
                                                <p>
                                                    Мы впервые решили протестировать рекламу через блогеров, и ваша
                                                    платформа стала незаменимым инструментом в этом процессе. Мы смогли
                                                    выбрать блогеров, которые идеально
                                                    соответствуют духу нашего бренда. И хотя мы были новичками в этом
                                                    деле, менеджеры компании поддержали нас на каждом этапе.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-brand">
                                        <SVGLogo/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="review">
                                    <div className="review-content card card-shadow round-xl">
                                        <div className="card-inner card-inner-lg">
                                            <div className="review-rating rating rating-sm">
                                                <ul className="rating-stars">
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star"/>
                                                    </li>
                                                </ul>
                                                <div className="rating-text">(4/5)</div>
                                            </div>
                                            <div className="review-text">
                                                <p>
                                                    Для нас было важно найти блогеров, которые смогут передать атмосферу
                                                    и дух наших книг. Благодаря вашему сервису это стало возможным.
                                                    Рекламные кампании, реализованные через вашу платформу, помогли нам
                                                    выйти на новый уровень продаж и узнаваемости.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-brand">
                                        <SVGLogo/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="review">
                                    <div className="review-content card card-shadow round-xl">
                                        <div className="card-inner card-inner-lg">
                                            <div className="review-rating rating rating-sm">
                                                <ul className="rating-stars">
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                    <li>
                                                        <em className="icon ni ni-star-fill"/>
                                                    </li>
                                                </ul>
                                                <div className="rating-text">(5/5)</div>
                                            </div>
                                            <div className="review-text">
                                                <p>
                                                    В индустрии микрозаймов доверие играет ключевую роль. Благодаря
                                                    вашему сервису, мы смогли сотрудничать с блогерами, которые успешно
                                                    и искренне рассказали о преимуществах нашего сервиса. Это не только
                                                    укрепило нашу репутацию, но и привлекло множество новых клиентов.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-brand">
                                        <SVGLogo/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-white" id="package">
                    <div className="container">
                        <div className="row justify-content-center text-tenter">
                            <div className="col-xl-7 col-lg-9">
                                <div className="section-head text-center mb-1">
                                    <h2 className="title text-dark mb-0">
                                        Тарифные планы
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="row align-items-center g-0">
                                    <div className="col-md-7">
                                        <div className="card card-shadow round-xl bg-dark is-dark pb-4 pb-md-0">
                                            <div className="card-inner card-inner-xl">
                                                <div className="text-block">
                                                    <h3 className="title mb-3">Под ключ</h3>
                                                    <ul className="list list-nostyle fs-15px mb-1">
                                                        <li>Индивидуальный план продвижения</li>
                                                        <li>Согласование каналов и блогеров</li>
                                                        <li>Прогноз результатов кампании</li>
                                                        <li>Помощь в подготовке и оптимизации креативов</li>
                                                        <li>Предоставление подробного отчёта</li>
                                                        <li>Личный менеджер</li>
                                                        <li className="note text-warning fs-14px">
                                                            Весь указанный бюджет идёт на покупку интеграций
                                                        </li>
                                                    </ul>
                                                    <ul className="btns-inline pt-4">
                                                        <li>
                                                            <a
                                                                href="/signup"
                                                                className="btn btn-round btn-xl btn-primary fs-16px"
                                                            >
                                                                Начните от 100 000₽
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div
                                            className="card card-shadow card-bd-right-3px round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                                            <div className="card-inner card-inner-lg">
                                                <div className="text-block is-compact pe-3">
                                                    <h4 className="title text-azure">Начальный</h4>
                                                    <ul className="list list-nostyle fs-15px">
                                                        <li>Список блогеров</li>
                                                        <li>Ручной подбор и фильтрация</li>
                                                        <li>До 10 активных кампаний</li>
                                                        <li>Поддержка через email</li>
                                                    </ul>
                                                    <ul className="btns-inline pt-2">
                                                        <li>
                                                            <a
                                                                href="/signup"
                                                                className="btn btn-lg btn-round btn-secondary"
                                                            >
                                                                {" "}
                                                                <span>Бесплатно</span>
                                                                <em className="icon ni ni-arrow-long-right"/>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section section-cta bg-purple is-dark" id="cta">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-md-8">
                                <div className="text-block is-compact">
                                    <h2 className="title fw-medium">Новые горизонты вместе с StarsWay</h2>
                                    <p className="pe-lg-7 ps-lg-7 text-white">
                                        Инфлюенсер-маркетинг как способ многократно увеличить вашу прибыль
                                    </p>
                                    <ul className="btns-inline justify-center">
                                        <li>
                                            <a href="/signup" className="btn btn-xl btn-danger">
                                                Подобрать блогеров
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <FooterBlock/>
        </div>
    );
};

export default MainPage;