import './assets/css/dashlite.css'
import './assets/css/theme.css'
import MainPage from "./components/MainPage";
import React, {useState, useEffect} from "react";

function LandingPageRoute() {
    const [isActivationPage, setIsActivationPage] = useState(false)
    useEffect(() => {
        if (window.location.pathname.includes('activate')) {
            console.log('act')
            setIsActivationPage(true)
        }
    }, []);

    if (!isActivationPage) {
        return <MainPage/>
    } else {
        return (
            <div className="nk-app-root">
                <div className="nk-main">

                    <div className="header-content my-auto py-5 bg-blue-dim" style={{height: '100vh'}}>
                        <div className="container py-5">
                            <div style={{textAlign: 'center'}}
                                className="row flex-lg-row-reverse align-items-center justify-content-between g-gs">
                                <div className="col-lg-5 mb-n3 mb-lg-0">
                                    <div className="header-image header-image-s2">
                                        <img style={{maxWidth: 300}}
                                            src="https://storage.yandexcloud.net/ezflow-bucket/emails/telegram.png"
                                            alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-10">
                                    <div className="header-caption">

                                        <h1 className="header-title ">
                                            Для активации вашей заявки нужно подтвердить ее через телеграм-бот
                                        </h1>

                                        <ul className="header-action btns-inline" style={{width: '100%'}}>
                                            <li  style={{width: '100%'}}>
                                                <a href="https://tnl.li/emLTG" onClick={() => {
                                                    setTimeout(() => {
                                                        window.location.replace('https://www.nsvz.ru/top?period=1&aff_sub=email&aff_sub2=d0q1&aff_sub3=btn&aff_sub4=activation')
                                                    }, 300)
                                                }}
                                                   target={"_blank"} className="btn btn-danger btn-lg">
                                                    <span>Активировать</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3></h3>

                </div>
            </div>
        )
    }
}

export default LandingPageRoute;
